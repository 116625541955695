import { FormEvent, useState } from "react";
import { TextArea } from "react-aria-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  createToast,
  Form,
  Icon,
  IconButton,
  ModalDialog,
  ModalTrigger,
} from "@archery-inc/design-system";
import StarRating from "../../../common/StarRating";
import { amplitude } from "../../../api/third-party";
import {
  Generation,
  getFormatFromAspectRatio,
  useGenerationQuery,
  useSendFeedbackForGeneration,
} from "../../../api/graphql/aiReveals";
import { isUUID } from "../../../common/uuid";
import { downloadFromUrl } from "../../../api/firebaseStorage";
import { TaskType } from "../../../__generated__/graphql";

export default function AiGenerationCompletePage() {
  const { generationId } = useParams();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const { data } = useGenerationQuery({ generationId: generationId! });
  const generation = data?.getAiAnimatedElementsGeneration as Generation;
  const preset = generation?.presets?.[0];
  const rating = generation?.feedback?.rating;
  const videoUrls = preset?.task?.parsedResult as string[];

  return (
    <div className="absolute z-30 sm:p-20 top-0 left-0 w-screen h-screen flex items-center justify-center bg-black/75">
      <IconButton
        icon="close"
        color="dark"
        className="size-10 absolute top-8 right-8"
        onPress={() => navigate("/ai-reveals/my-ai-generations")}
      />
      {data ? (
        <div
          className="max-h-[calc(100vh-160px)] relative"
          style={{
            aspectRatio: getFormatFromAspectRatio(preset?.aspectRatio),
          }}
        >
          <video
            className="rounded-4"
            autoPlay
            loop
            playsInline
            onLoadedMetadata={() => setIsLoaded(true)}
            src={videoUrls[0]}
          />
          {isLoaded && (
            <Button
              className="bottom-3 right-3 absolute"
              leftIcon="download"
              color="black"
              onPress={() => {
                if (!preset) return;
                amplitude.logEvent("Web:Client:AiReveals:Download", {
                  model:
                    preset.task?.type === TaskType.RunwayImageToVideo
                      ? "runway"
                      : "controlnet",
                  generationId,
                  presetId: isUUID(preset.presetId)
                    ? "custom"
                    : preset.presetId,
                  dbPresetId: preset.presetId,
                });
                void downloadFromUrl(
                  videoUrls[0],
                  `${preset.presetId}-${new Date().getTime()}`,
                );
              }}
            >
              <FormattedMessage id="share_video_download" />
            </Button>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-4 size-60 flex items-center justify-center">
          <Icon icon="spinner" className="bg-black size-6" />
        </div>
      )}
      <Feedback
        generationId={generationId!}
        presetId={preset?.presetId}
        rating={rating}
      />
    </div>
  );
}

function Feedback({
  generationId,
  presetId,
  rating,
}: {
  generationId: string;
  presetId?: string;
  rating?: number;
}) {
  const [updateGenerationFeedback] = useSendFeedbackForGeneration();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRatingChange = (newRating: number) => {
    amplitude.logEvent("Web:Client:AiReveals:Feedback:StarRating:StarClicked", {
      rating: newRating,
      presetId,
      generationId,
    });
    setModalOpen(true);
    void updateGenerationFeedback({
      variables: { generationId, rating: newRating },
    });
  };

  return (
    <div className="absolute bottom-0 w-full p-5 flex items-center justify-center">
      <div className="bg-white w-fit p-2 rounded-2 shadow-y-plus-1">
        <StarRating
          rating={rating}
          onRatingChange={(newRating) => void handleRatingChange(newRating)}
          frozen={!!rating}
        />
        <ModalTrigger onOpenChange={setModalOpen} isOpen={isModalOpen}>
          {/* TODO: this is to prevent a warning, we shouldn't use the modeltrigger if the modal is controlled */}
          <Button className="hidden" />
          {rating && (
            <FeedbackForm
              generationId={generationId}
              rating={rating}
              presetId={presetId}
            />
          )}
        </ModalTrigger>
      </div>
    </div>
  );
}

function FeedbackForm({
  generationId,
  presetId,
  rating,
}: {
  generationId: string;
  presetId?: string;
  rating: number;
}) {
  const intl = useIntl();
  const [updateGenerationFeedback, { loading }] =
    useSendFeedbackForGeneration();

  const handleSubmit = (e: FormEvent<HTMLFormElement>, close: () => void) => {
    e.preventDefault();
    const feedback = new FormData(e.currentTarget).get("content") as string;
    amplitude.logEvent("Web:Client:AiReveals:Feedback:Form:Submited", {
      presetId,
      generationId,
      rating,
    });
    void updateGenerationFeedback({
      variables: { generationId, message: feedback || null, rating },
    }).then(() => {
      close();
      createToast(
        <p>
          <FormattedMessage id="ai_reveal_feedback_thanks_toast_msg" />
        </p>,
      );
    });
  };

  function getPlaceholder() {
    switch (rating) {
      case 1:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_one" });
      case 2:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_two" });
      case 3:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
      case 4:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_four" });
      case 5:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_five" });
      default:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
    }
  }

  return (
    <ModalDialog
      className="flex flex-col gap-3 p-5 relative"
      containerClassName="w-full max-w-md"
    >
      {({ close }) => (
        <Form onSubmit={(e) => handleSubmit(e, close)}>
          <IconButton
            onPress={close}
            icon="close"
            color="grey"
            className="absolute top-4 right-4"
          />
          <StarRating rating={rating} frozen />
          <div>
            <TextArea
              autoFocus
              className="border-1 border-black rounded-2 p-2 w-full mt-2"
              name="content"
              maxLength={Infinity}
              placeholder={getPlaceholder()}
            />
          </div>
          <div className="flex gap-2 mt-2 justify-center items-center">
            <Button onPress={close} color="grey">
              <FormattedMessage id="common_cancel" />
            </Button>
            <Button
              type="submit"
              color="black"
              leftIcon={loading ? "spinner" : undefined}
              isDisabled={loading}
            >
              <FormattedMessage id="send_feedback_txt" />
            </Button>
          </div>
        </Form>
      )}
    </ModalDialog>
  );
}
