import { cn } from "@archery-inc/design-system";
import { useState } from "react";

interface StarRatingProps {
  rating?: number | null;
  frozen?: boolean;
  onRatingChange?: (newRating: number) => void;
}

export default function StarRating({
  rating = null,
  frozen = false,
  onRatingChange,
}: StarRatingProps) {
  const [hoveredStars, setHoveredStars] = useState(0);
  const totalStars = 5;

  function calculateRating(e: React.MouseEvent<HTMLElement>) {
    return Math.max(
      1,
      Math.round(
        (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * totalStars,
      ),
    );
  }

  return (
    <div
      className={cn("flex items-center gap-1", {
        "cursor-pointer": !frozen,
        "pointer-events-none": frozen,
      })}
      onClick={(e) => {
        const newRating = calculateRating(e);
        onRatingChange?.(newRating);
      }}
      onMouseMove={(e) => setHoveredStars(calculateRating(e))}
      onMouseLeave={() => setHoveredStars(0)}
    >
      {[...(Array(totalStars) as number[])].map((_, index) => (
        <svg
          key={index}
          className={cn(`size-5 pointer-events-none`, {
            "text-yellow": frozen
              ? index < Number(rating)
              : index < hoveredStars,
          })}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      ))}
    </div>
  );
}
