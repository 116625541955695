export function isUUID(str: string) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}

export async function createUUID() {
  try {
    return crypto.randomUUID();
  } catch (e) {
    const uuid = await import("uuid");
    return uuid.v4();
  }
}
