import * as Sentry from "@sentry/react";
import Amplitude from "amplitude-js";
import { loadStripe } from "@stripe/stripe-js";
import { version } from "../../package.json";

export const amplitude = Amplitude.getInstance();

export function initThirdPartyApis() {
  initAmplitude();
  initSentry();
}

export async function redirectToStripeSession(sessionId: string) {
  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
  await stripe?.redirectToCheckout({ sessionId });
}

function initAmplitude() {
  if (!import.meta.env.VITE_AMPLITUDE_ID) return;

  const url = new URL(window.location.href);
  const deviceId = url.searchParams.get("adid") ?? "";

  amplitude.init(import.meta.env.VITE_AMPLITUDE_ID, undefined, { deviceId });

  if (deviceId) {
    url.searchParams.delete("adid");
    history.replaceState(null, "", url);
  }
}

function initSentry() {
  if (!import.meta.env.VITE_SENTRY_DSN) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    release: version,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
