import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { gql } from "../../__generated__";
import { loadStripe } from "@stripe/stripe-js";
import {
  AiAnimatedElementsGeneration,
  AiAnimatedElementsGenerationStatus,
  AiRevealAspectRatio,
  GenerateAiAnimatedPictureInputFromBase64Query,
  GenerateAiAnimatedPictureInputFromPictureUrlQuery,
  TaskStatus,
} from "../../__generated__/graphql";
import { removeOneCredit } from "./profile";

export type Generation = AiAnimatedElementsGeneration;

export const GENERATION_FRAGMENT = gql(`
  fragment GenerationFragment on AiAnimatedElementsGeneration {
    _id
    progress
    generationId
    inputBucketInfo {
      bucketName
      filePath
    }
    status
    presets {
      aspectRatio
      imagePosition
      margin
      customPrompt
      coordinates {
        x
        y
      }
      backgroundColor
      presetId
      task {
        type
        status
        parsedResult
        thumbnailData {
          thumbHash
          thumbnailUrl
        }
      }
    }
  }
`);

const GET_PRESETS = gql(`
  query Presets($filters: AiAnimatedElementPresetsFilters) {
    getAiAnimatedElementPresets(filters: $filters) {
      presets {
        modelData {
          modelInfo {
            modelName
          }
        }
        presetId
        isPro
        isPresetNew
        previewVideoUrl
      }
      nextCursor
    }
  }
`);

export function usePresetsQuery({ showHidden = false } = {}) {
  return useQuery(GET_PRESETS, {
    variables: { filters: { showHidden } },
    fetchPolicy: "network-only",
    context: {
      fetchOptions: {
        next: { revalidate: 0 },
      },
    },
  });
}

const GET_GENERATION = gql(`
  query GetAiAnimatedElementsGeneration($generationId: ID!) {
    getAiAnimatedElementsGeneration(generationId: $generationId) {
      ...GenerationFragment
    }
  }
`);

export function useGenerationQuery({ generationId }: { generationId: string }) {
  return useQuery(GET_GENERATION, { variables: { generationId } });
}

const MY_GENERATIONS = gql(`
  query MyGenerations($limit: Int, $cursor: String) {
    myAiGenerations(limit: $limit, cursor: $cursor) {
      data {
        ...GenerationFragment
      }
      nextCursor
    }
  }
`);

export function useMyGenerationsQuery(variables?: {
  limit?: number;
  cursor?: string;
}) {
  return useQuery(MY_GENERATIONS, {
    variables,
    fetchPolicy: "cache-and-network",
  });
}

const CREATE_AI_FROM_PRESET = gql(`
  mutation CreateAiAnimatedElementsWithPresets(
    $inputFileUrl: String!
    $presets: [PresetConfig!]
    $inputBucketInfo: BucketInfoInput
  ) {
    createAiAnimatedElementsWithPresets(
      inputFileUrl: $inputFileUrl
      presets: $presets
      inputBucketInfo: $inputBucketInfo
    ) {
      ...GenerationFragment
    }
  }
`);

export function useCreateAi() {
  return useMutation(CREATE_AI_FROM_PRESET, {
    update(cache, { data }) {
      removeOneCredit(cache);
      const prevdata = cache.readQuery({ query: MY_GENERATIONS });
      if (data?.createAiAnimatedElementsWithPresets)
        cache.writeQuery({
          query: MY_GENERATIONS,
          data: {
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: [
                data?.createAiAnimatedElementsWithPresets,
                ...(prevdata?.myAiGenerations.data ?? []),
              ],
            },
          },
        });
    },
  });
}

const NOTIFY_EMAIL_ON_GENERATION_COMPLETE = gql(`
  mutation NotifyEmailOnGenerationComplete(
    $generationId: ID!
  ) {
    notifyEmailOnGenerationComplete(
      generationId: $generationId
    ) {
      generationId
      errorMessages
    }
  }
`);

export function useNotifyEmailOnGenerationComplete(generationId: string) {
  return useMutation(NOTIFY_EMAIL_ON_GENERATION_COMPLETE, {
    variables: { generationId },
  });
}

const SEND_FEEDBACK_FOR_GENERATION = gql(`
  mutation SendFeedbackForGeneration(
    $generationId: ID!
    $rating: Int!
    $message: String
  ) {
    sendFeedbackForGeneration(
      generationId: $generationId
      rating: $rating
      message: $message
    ) {
      generationId
    }
  }
`);

export function useSendFeedbackForGeneration() {
  const [mutate, options] = useMutation(SEND_FEEDBACK_FOR_GENERATION, {});
  const client = useApolloClient();

  const update: typeof mutate = (params) => {
    if (!params?.variables) return mutate(params);
    client.cache.updateQuery(
      {
        query: GET_GENERATION,
        variables: { generationId: params.variables.generationId },
      },
      (data) =>
        data
          ? {
              ...data,
              getAiAnimatedElementsGeneration: {
                ...data.getAiAnimatedElementsGeneration,
                feedback: params.variables,
              },
            }
          : data,
    );
    return mutate(params);
  };

  return [update, options] as const;
}

const GENERATE_ANIMATED_PICTURE_INPUT_FROM_BASE_64 = gql(`
  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {
  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {
    removedBackgroundPictureUrl
    bucketName
    filePath
    backgroundColorToUse
  }
}
`);

export function useGenerateWorkflowInputWithBase64Encoded({
  base64Data,
  fileName,
  onCompleted,
  skip,
}: {
  base64Data: string;
  fileName: string;
  onCompleted?: (data: GenerateAiAnimatedPictureInputFromBase64Query) => void;
  skip?: boolean;
}) {
  return useQuery(GENERATE_ANIMATED_PICTURE_INPUT_FROM_BASE_64, {
    variables: {
      base64Data,
      fileName,
    },
    onCompleted,
    skip,
  });
}

const GENERATE_ANIMATED_PICTURE_INPUT = gql(`
  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!, $originalFilePathOnBucket: String) {
    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl, originalFilePathOnBucket: $originalFilePathOnBucket) {
      removedBackgroundPictureUrl
      bucketName
      filePath
      backgroundColorToUse
    }
  }
`);

export function useGenerateWorkflowInput({
  url,
  fileLocation,
  onCompleted,
  skip,
}: {
  url: string;
  fileLocation: string;
  onCompleted?: (
    data: GenerateAiAnimatedPictureInputFromPictureUrlQuery,
  ) => void;
  skip?: boolean;
}) {
  return useQuery(GENERATE_ANIMATED_PICTURE_INPUT, {
    variables: {
      pictureUrl: url,
      originalFilePathOnBucket: fileLocation,
    },
    onCompleted,
    skip,
  });
}

const CANCEL_GENERATION = gql(`
  mutation CancelGeneration($generationId: ID!) {
    cancelGeneration(generationId: $generationId) {
      generationId
      status
    }
  }
`);

export function useCancelGeneration(generationId: string) {
  return useMutation(CANCEL_GENERATION, {
    variables: { generationId },
    optimisticResponse: {
      __typename: "Mutation",
      cancelGeneration: {
        generationId,
        status: AiAnimatedElementsGenerationStatus.Canceled,
      },
    },
    update(cache, { data }) {
      const prevdata = cache.readQuery({ query: MY_GENERATIONS });
      cache.writeQuery({
        query: MY_GENERATIONS,
        data: {
          ...prevdata,
          myAiGenerations: {
            ...prevdata?.myAiGenerations,
            data: prevdata?.myAiGenerations.data.map((g) =>
              (g as Generation).generationId === generationId
                ? {
                    ...g,
                    status: data?.cancelGeneration.status,
                  }
                : g,
            ),
          },
        },
      });
    },
  });
}

const SEND_EMAIL = gql(`
  mutation SubscribeToWaitingList($email: String!) {
    subscribeToWaitingList(email: $email)
  }
`);

export function useSubscribeToWaitingList() {
  return useMutation(SEND_EMAIL);
}

const CREATE_FROM_PROJECT_FROM_AI = gql(`
  mutation CreateProjectFromGeneration($generationId: ID!) {
    createProjectFromGeneration(generationId: $generationId) {
      _id
    }
  }
`);

export function useCreateProjectFromGeneration() {
  return useMutation(CREATE_FROM_PROJECT_FROM_AI);
}

const GET_AI_TOKENS = gql(`
  query GetAiTokensPlans {
    getAiTokensPlans {
      productId
      name
      currency {
        isoCode
        symbol
        showBefore
      }
      tokensGiven
      price
    }
  }
`);

export function useGetAiTokens() {
  return useQuery(GET_AI_TOKENS);
}

const STRIPE_SESSION = gql(`
  query GetOneOffCheckoutSession($productId: String!) {
    getOneOffCheckoutSession(productId: $productId) {
      sessionId
    }
  }
`);

export function useStripeSession() {
  const [getSession] = useLazyQuery(STRIPE_SESSION);
  return async (productId: string) => {
    const stripe = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    );
    const { data } = await getSession({ variables: { productId } });
    const sessionId = data?.getOneOffCheckoutSession?.sessionId;
    if (sessionId) await stripe?.redirectToCheckout({ sessionId });
  };
}

export function getFormatFromAspectRatio(ratio: AiRevealAspectRatio): string {
  switch (ratio) {
    case AiRevealAspectRatio.Landscape:
      return "16 / 9";
    case AiRevealAspectRatio.Portrait:
      return "9 / 16";
    case AiRevealAspectRatio.Vertical:
      return "4 / 5";
    case AiRevealAspectRatio.Square:
      return "1 / 1";
    case AiRevealAspectRatio.RunwayLandscape:
      return "5 / 3";
    case AiRevealAspectRatio.RunwayPortrait:
      return "3 / 5";
    default:
      return "9 / 16";
  }
}

const CREATE_VARIATION = gql(`
  mutation CreateGenerationVariation($generationId: ID!) {
    createGenerationVariation(generationId: $generationId) {
      ...GenerationFragment
    }
  }
`);

export function useCreateVariation() {
  return useMutation(CREATE_VARIATION, {
    update(client, { data }) {
      const generation = data?.createGenerationVariation;
      if (generation) {
        removeOneCredit(client);
        // Add generation to query
        const prevdata = client.readQuery({ query: MY_GENERATIONS });
        client.writeQuery({
          query: MY_GENERATIONS,
          overwrite: true,
          data: {
            ...prevdata,
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: [generation, ...(prevdata?.myAiGenerations.data ?? [])],
            },
          },
        });
      }
    },
  });
}

const GET_GENERATIONS_PROGRESS = gql(`
  query GetGenerations($generationsIds: [String!]!) {
    getGenerations(generationsIds: $generationsIds) {
      ...GenerationFragment
    }
  }
`);

export function useGetGenerationsProgress() {
  return useLazyQuery(GET_GENERATIONS_PROGRESS, {
    notifyOnNetworkStatusChange: true,
  });
}

export const isGenerationProcessing = (generation: Generation) => {
  return [
    AiAnimatedElementsGenerationStatus.Starting,
    AiAnimatedElementsGenerationStatus.FetchingPresets,
    AiAnimatedElementsGenerationStatus.GeneratingTasks,
    AiAnimatedElementsGenerationStatus.TasksSent,
  ].includes(generation.status);
};

export const isGenerationComplete = (generation: Generation) => {
  return [
    AiAnimatedElementsGenerationStatus.TasksPartialCompleted,
    AiAnimatedElementsGenerationStatus.TasksCompletedWithErrors,
    AiAnimatedElementsGenerationStatus.TasksCompleted,
  ].includes(generation.status);
};

export const isGenerationQueued = (generation: Generation) => {
  // We suppose that we already checked that the generation is processing
  return generation?.presets?.[0].task?.status !== TaskStatus.Processing;
};

export const getPendingGenerationIds = (generations: Generation[]) =>
  generations
    .filter(isGenerationProcessing)
    .map((generation) => generation.generationId);

const DELETE_GENERATION = gql(`
  mutation DeleteAiGeneration($generationId: ID!) {
    deleteAiGeneration(generationId: $generationId) {
      generationId
    }
  }
`);

export function useDeleteGeneration({
  generationId,
}: {
  generationId: string;
}) {
  return useMutation(DELETE_GENERATION, {
    variables: { generationId },
    optimisticResponse: {
      __typename: "Mutation",
      deleteAiGeneration: {
        generationId,
      },
    },
    update(client, { data }) {
      const generationId = data?.deleteAiGeneration.generationId;
      if (generationId) {
        // Remove generation from query
        const prevdata = client.readQuery({ query: MY_GENERATIONS });
        client.writeQuery({
          query: MY_GENERATIONS,
          overwrite: true,
          data: {
            ...prevdata,
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: prevdata?.myAiGenerations.data.filter(
                (g) => (g as Generation).generationId !== generationId,
              ),
            },
          },
        });
      }
    },
  });
}
